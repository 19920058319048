import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import "./Navbar.css";
import {FaPaperPlane} from "react-icons/fa";
import {BsTwitterX} from "react-icons/bs";
import {IoMdRocket} from "react-icons/io";

const Navbar = () => {

    const [navToggle, setNavToggle] = useState(false);
    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }

  return (
    <nav className='navbar w-100 flex'>
        <div className='container w-100'>
            <div className='navbar-content flex fw-7'>
                <div className='brand-and-toggler flex flex-between w-100'>
                    <Link to = "/" className='navbar-brand fs-26'>$MISSOR</Link>
                    <div type = "button" className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`} onClick={navHandler}>
                        <div className='bar-top'></div>
                        <div className='bar-middle'></div>
                        <div className='bar-bottom'></div>
                    </div>
                </div>

                <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                    <div className='navbar-collapse-content'>
                        <ul className='navbar-nav'>
                            <li className='text-white'></li>
                            <li className='text-white'></li>
                            <li className='text-white'></li>
                            <li className='text-white'></li>
                        </ul>
                        <ul className='navbar-social flex'>
                            <li className='text-white'>
                                <Link to = "https://twitter.com/TheMissoooorSol" className='flex flex-center'><BsTwitterX /></Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "https://t.me/MISSOOOOOOR" className='flex flex-center'><FaPaperPlane /></Link>
                            </li>

                        </ul>
                        <div className='navbar-btns'>
                            <button type="button" className='btn'
                                onClick={(e) => {
                                e.preventDefault();
                                window.location.href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in'; //raydium link
                                }}><IoMdRocket /> <span>Buy $MISSOR</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar
import React from 'react';
import "./About.css";
import images from '../../constants/images';

const About = () => {
  return (
    <section className='about section-p bg-dark' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {images.miss2} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h6>Welcome to <br/>
                            THE MISSOOOOOR <br/> Crew!</h6>
                    </div>
                    <p className='text'>
                    You're now officially part of the most hilariously unfortunate trading squad on the internet. <br/><br/>
                    If you've ever: Cried over a mooning coin from the sidelines,
                    Been glued to the screen for hours and still missed the action,
                    Facepalmed after selling just before the rally.<br/>
                    Then you're in the right place!
                    </p><br/>

                    <p className = "text"><h4>"MISSOOOOOOR", </h4>the person who's always connected, yet consistently misses out on every major move in the cryptocurrency market. They're constantly online but seem to have a knack for making the wrong decisions, or hesitating and then ruminating over their losses and what-ifs. The Missor is the personification of FOMO (fear of missing out) and a cautionary tale about the chaotic and unpredictable nature of trading without research or strategy.</p>    
                </div>
            </div>
        </div>
    </section>
  )
}

export default About
import { SiLiquibase } from "react-icons/si";

import { GiBuyCard } from "react-icons/gi";
import { IoWalletOutline } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <BsBank  style = {{ fill: gradient }} />,
        title: "Total supply",
        text: "999,999,999"
    },
    {
        id: 2,
        icon: <GiBuyCard style = {{ fill: gradient }} />,
        title: "Sell Tax",
        text: "0 %"
    },
    {
        id: 3,
        icon: <IoWalletOutline style = {{ fill: gradient }} />,
        title: "Dev Wallet",
        text: "0"
    },
    {
        id: 4, 
        icon: <SiLiquibase style = {{ fill: gradient }} />,
        title: "Liquidity",
        text: "Burned"

    },
    {
        id: 5,
        icon: <GiBuyCard style = {{ fill: gradient }} />,
        title: "Buy Tax",
        text: "0 %"
    },
    {
        id: 6,
        icon: <IoWalletOutline style = {{ fill: gradient }} />,
        title: "Marketing Wallet",
        text: "0"
    }
];

const about = [
    {
        id: 7,
        text: "Lormeemputpat mauris lobortis. Nullec scelerisque auctor"
    }
]

const ticker = "$MISSOR"
const telegram = 'https://t.me/MISSOOOOOOR'





const sections = {services, about , ticker, telegram};

export default sections;
import React from 'react';
import Navbar from '../Navbar/Navbar';
import "./Header.css";
import {FaPaperPlane} from "react-icons/fa";
import images from '../../constants/images';
import data from '../../constants/data'
const Header = () => {
  return (
    <header className='header flex flex-center flex-column'>
        <Navbar />
        <div className='container'>
            <div className='header-content text-center flex flex-column'>
                <h1 className='text-uppercase header-title'>$MISSOR</h1>
                <img src={images.miss1}  alt=""/>
            
                <a href = {data.telegram} className='btn header-btn btn-blue'>
                    <FaPaperPlane /> <span>Telegram</span>
                </a>
            </div>
        </div>
    </header>
  )
}

export default Header